import React from 'react';
import { Helmet } from 'react-helmet';
import '../main.scss';
import Navbar from './Navbar';
import Slider from './Slider';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Salon Looking Good - Parturi-kampaamo itis</title>
                <link rel="canonical" href="https://www.salonlookinggood.fi" />
            </Helmet>
            <header>
                <Navbar />
            </header>
            <main>{children}</main>
            <Slider />
            <Footer />
        </>
    );
};

export default Layout;

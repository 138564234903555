// data
export const links = [
    {
        text: 'Etusivu',
        url: '/',
        description:
            "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
        color: '#E95800',
    },
    {
        text: 'Parturi-kampaamo palvelut',
        url: '/parturi-kampaamo',
        description:
            "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
        color: '#1099A8',
    },
    {
        text: 'Galleria',
        url: '/galleria',
        description:
            "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
        color: '#1099A4',
    },
    {
        text: 'Kynnet',
        url: '/kynnet',
        description:
            "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
        color: '#BC027F',
    },
    {
        text: 'Jalkahoito',
        url: '/jalkahoito',
        description:
            "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
        color: '#BC027F',
    },
    {
        text: 'Yhteystiedot',
        url: '/yhteystiedot',
        description:
            'Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.',
        color: '#0D96F2',
    },
];

import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>Yhteystiedot</h4>
                        <p>Y-tunnus: 3420595-2</p>
                        <address>
                            <a href="https://maps.app.goo.gl/1Qezpw59r3eUBQja7">
                                Gotlanninkatu 11
                            </a>
                        </address>
                        <address>00930 Helsinki</address>
                        <address>
                            Kauppakeskus Itis, Helsinki
                            <br />
                            <a href="tel:0503801400">puh: 050 38 01 400</a>
                        </address>
                    </div>
                    <div className="col-md-4">
                        <h4>Aukioloajat:</h4>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">ma-pe: 9-19</li>
                            <li className="list-group-item">la: 10-16</li>
                            <li className="list-group-item">
                                su: sopimuksen mukaan
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <p>
                            Salon Looking Good Itis OY ©2024. All Rights
                            Reserved. Made with &#10084; by{' '}
                            <a href="https://devdesign.fi">DevDesign</a>.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

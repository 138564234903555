import React from 'react';
import { Link } from 'gatsby';

const Slider = () => {
    return (
        <section className="contact-section section-padding" data-aos="fade-up">
            <div className="bg-layer">
                <span className="project-ctrl"></span>
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
                <span className="line line-4"></span>
                <span className="project-ctrl"></span>
            </div>
            <div className="container">
                <div className="contact-box">
                    <div className="box">
                        <div className="bg cover-bg"></div>
                        <h2>
                            Varaa aikasi <br /> jo tänään
                        </h2>
                        <Link to="/yhteystiedot" className="custom-btn">
                            <span className="custom-btn__label">
                                Ota yhteyttä
                            </span>

                            <span className="custom-btn__icon">
                                <span className="custom-btn__icon-small">
                                    <svg
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                    >
                                        <polygon points="33.7,95.8 27.8,90.5 63.9,50 27.8,9.5 33.7,4.2 74.6,50 "></polygon>
                                    </svg>
                                </span>

                                <span className="custom-btn__icon-circle">
                                    <svg
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 100"
                                    >
                                        <path
                                            className="bottomcircle"
                                            d="M18.2,18.2c17.6-17.6,46-17.6,63.6,0s17.6,46,0,63.6s-46,17.6-63.6,0"
                                        ></path>
                                        <path
                                            className="topcircle"
                                            d="M18.2,18.2c17.6-17.6,46-17.6,63.6,0s17.6,46,0,63.6s-46,17.6-63.6,0"
                                        ></path>
                                    </svg>
                                </span>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Slider;

import React, { useState } from 'react';
import { links } from '../data/links';
import { Link } from 'gatsby';
import logo from '../images/logo.png';

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const clickHandler = () => {
        setNavbarOpen(!navbarOpen);
    };
    return (
        <nav
            className="navbar navbar-expand-lg navbar-dark bg-dark"
            style={{ backgroundColor: '#434343' }}
        >
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="Salon looking good logo" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarMenu"
                    aria-controls="navbarMenu"
                    onClick={clickHandler}
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className={
                        navbarOpen
                            ? 'navbar-collapse'
                            : 'collapse navbar-collapse'
                    }
                    id="navbarMenu"
                >
                    <ul className="navbar-nav">
                        {links &&
                            links.map((link) => {
                                return (
                                    <li key={link.color} className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={link.url}
                                        >
                                            {link.text}
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
